/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getS3File = /* GraphQL */ `
  query GetS3File($id: ID!) {
    getS3File(id: $id) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listS3Files = /* GraphQL */ `
  query ListS3Files(
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Files(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncS3Files = /* GraphQL */ `
  query SyncS3Files(
    $filter: ModelS3FileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncS3Files(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
