import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./SubMenu";
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Fof from "../../pages/Fof/Fof";
class SideBar extends React.Component {
  render() {
    return (
     
      <div style={{height:'100vh'}}className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="sidebar-header">
          <Button
            variant="link"
            onClick={this.props.toggle}
            style={{ color: "#fff" }}
            className="mt-4"
          >
            <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
          </Button>
          <h3>Moure Development LLC</h3>
        </div>

        <Nav className="flex-column pt-2">
          <p className="ml-3">Heading</p>
          {/* first way of linking */}
          <Link to={"/"}>{
          <Nav.Item className="active">
            <Nav.Link href="/">
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Home
            </Nav.Link>
          </Nav.Item> 
  }         
          </Link>

          <Nav.Item>
            {/* second way of linking */}
            <Nav.Link href="/FE"> 
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
              File Explorer
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/about">
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
              About
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/portfolio">
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              Portfolio
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/faq">
              <FontAwesomeIcon icon={faQuestion} className="mr-2" />
              FAQ
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/contact">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              Contact
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/chat">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              Chat
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/p">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              p ig t saver
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/yt">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              yt / video saver
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/am">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              music saver (apple music api)
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/gs">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              GITHUB SORTER
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/dev">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              ADMIN PAGE
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/notes">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              STICKY KANBAN
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/movies">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              MOVIES
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/calendar">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              CALENDAR
            </Nav.Link>
          </Nav.Item>
          
          <Nav.Item>
            <Nav.Link href="/maps">
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              MAPS
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      
    );
  }
}

export default SideBar;
