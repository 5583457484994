import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Fof from "./pages/Fof/Fof";
import FE from "./pages/FE/FE";
import Amplify, { Storage, API, Auth, graphqlOperation } from "aws-amplify";
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
class App extends React.Component {
  constructor(props) {
    super(props);

    // Moblie first
    this.state = {
      isOpen: false,
      isMobile: true
    };

    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile
      });
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */



  componentDidMount() {
   // signIn()
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };





  render() {
    return (
        <Router>
   <div>
   <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route path="/FE" element={<FE/>} />

      <Route path=":id" element={<Fof/>} />
      </Routes>
        </div>



      </Router>
    );
  }
}
const signIn = async (username,password) => {
  try {
      const user = await Auth.signIn(username, password);
  } catch (error) {
      console.log('error signing in', error);
  }
}
export default App;


      // //*******CREATES CASES*****
      // const DataFromCreation = await API.graphql(
      //   graphqlOperation(mutations.createCases, { input: payload })
      // );