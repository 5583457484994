import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FE.css";
import SideBar from "../../components/sidebar/SideBar";
import DashboardContent from "../../components/content/DashboardContent";
import Navbar from "../../components/Navbar/Navbar";
import { listS3Files as ListS3Files } from "../../graphql/queries"
import Amplify, { Storage, API, Auth, graphqlOperation } from "aws-amplify";

class App extends React.Component {
  constructor(props) {
    super(props);

    // Moblie first
    this.state = {
      isOpen: false,
      isMobile: true
    };

    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile
      });
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */
  async componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));

      let res = await API.graphql({
        query: ListS3Files,
      });
      console.log(res)
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div>
        <div className='rowC'>
            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
        <DashboardContent toggle={this.toggle} isOpen={this.state.isOpen} />
   
        </div>

  
        </div>
 
    );
  }
}

export default App;
