import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Fof.css";
// import * as mutations from "src/graphql/mutations";
import Amplify, { Storage, API, Auth, graphqlOperation } from "aws-amplify";

class Fof extends React.Component {
  constructor(props) {
    super(props);

    // Moblie first
    this.state = {
      isOpen: false,
      isMobile: true,

     
    };
    this.signedURL= "sda"
    this.previousWidth = -1;
  } 

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile
      });
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */

 async componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
     await Storage.get(`crying.jpg`, { download: true })
    .then(res => {
      this.signedURL = URL.createObjectURL(res.Body); 
       this.setState({signedURL: URL.createObjectURL(res.Body)})
    })

    console.log(this.signedURL)
   // console.log(await Storage.get(''))

 }
  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

render() {

    return (
      <div className="mainContainer">
        <div className="title">
        Don't cri
        </div>
        
     
        <img className="photo" src={this.signedURL} />
      </div>
    );
  }
}

export default Fof;
