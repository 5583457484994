import React from "react";
import classNames from "classnames";
import { Container, Card, Button } from "react-bootstrap";
import NavBar from "./Navbar";
import "./DashboardContent.css";

class DashboardContent extends React.Component {
  render() {
    return (
      <Container
        fluid
        className={classNames("content", { "is-open": this.props.isOpen })}
      >
      <NavBar toggle={this.props.toggle} />
      <div className="container" style={{ marginBottom: '15vh' }}>

  <div id="card1" style={{ marginRight: '5vh' }}>  <Card bg="success"  key="success" text="white" style={{ height: '500px' }}>
  <Card.Header as="h5">Weather</Card.Header>
  <Card.Body>
    <Card.Title>Special title treatment</Card.Title>
    <Card.Text>
      With supporting text below as a natural lead-in to additional content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card></div>

<div> <Card bg="success"  key="success" text="white" style={{ height: '500px' }}>
  <Card.Header as="h5">Stocks</Card.Header>
  <Card.Body>
    <Card.Title>Special title treatment</Card.Title>
    <Card.Text>
      With supporting text below as a natural lead-in to additional content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card></div>
</div>

<div className="container">
<div style={{ marginRight: '5vh' }}>  <Card style={{ height: '500px' }}>
  <Card.Header as="h5">Movies</Card.Header>
  <Card.Body>
    <Card.Title>Special title treatment</Card.Title>
    <Card.Text>
      With supporting text below as a natural lead-in to additional content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card></div>



<div>  <Card style={{ height: '500px' }}>
  <Card.Header as="h5">News</Card.Header>
  <Card.Body>
    <Card.Title>Special title treatment</Card.Title>
    <Card.Text>
      With supporting text below as a natural lead-in to additional content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card></div></div>


      </Container>
    );
  }
}

export default DashboardContent;